import Classnames from 'classnames'
import HeaderHero from '../components/header-hero'
import Icon from '../components/icon'
import Layout from '../components/layout'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'
import RenderContentBlocks from '../helpers/render-content-blocks'
import SEO from '../components/seo'
import { useLatestResource } from '../hooks/latest-resource'
// noinspection ES6CheckImport
import {
    Configure,
    connectCurrentRefinements,
    Hits,
    InstantSearch,
    Menu,
    Pagination,
    PoweredBy,
    RefinementList,
    SortBy,
} from 'react-instantsearch-dom'
import { createURL, DEBOUNCE_TIME, DebouncedSearchBox, Hit, searchClient, searchStateToUrl, urlToSearchState } from '../helpers/algolia'
import React, { useState } from 'react'

import './news.scss'
import '../components/search.scss'
import '../components/resource-search.scss'

const ClearRefinements = ({ className, attribute, refine, items }) => {
    const filteredItems = items && items.filter((item) => item.attribute === attribute)

    return (
        <button className={className} onClick={() => refine(filteredItems)} disabled={!items.length}>
            Clear {attribute} selection
        </button>
    )
}

const CustomClearRefinements = connectCurrentRefinements(ClearRefinements)

function News({ location }) {
    // Search Parameters
    const [searchState, setSearchState] = useState(urlToSearchState(location))
    const [debouncedSetState, setDebouncedSetState] = useState(null)

    const [tagsActive, toggleTagsActive] = useState(false)
    const { latestResource } = useLatestResource().singleContentfulResource

    const LatestResource = latestResource && latestResource[0].node

    const tagsClass = Classnames({
        'is-active': tagsActive,
    })

    const onSearchStateChange = (updatedSearchState) => {
        // noinspection JSCheckFunctionSignatures
        clearTimeout(debouncedSetState)

        setDebouncedSetState(
            setTimeout(() => {
                navigate(searchStateToUrl(location, updatedSearchState)).then()
            }, DEBOUNCE_TIME),
        )

        setSearchState(updatedSearchState)
    }

    const tagsDropDownToggle = () => {
        toggleTagsActive(!tagsActive)
    }

    return (
        <Layout>
            <SEO title="News" />
            <HeaderHero
                title={LatestResource.title}
                tags={LatestResource.tags}
                image={LatestResource.image}
                preview={RenderContentBlocks(LatestResource.contentBlocks)}
                readMoreLink={`/news/${LatestResource.slug}`}
            />
            <div className="resources">
                <InstantSearch
                    indexName="resources"
                    searchClient={searchClient}
                    searchState={searchState}
                    onSearchStateChange={onSearchStateChange}
                    createURL={createURL}
                >
                    <SortBy
                        defaultRefinement="resources_date_desc"
                        items={[
                            { value: 'resources_date_desc', label: 'Newest to Oldest' },
                            { value: 'resources_date_asc', label: 'Oldest to Newest' },
                        ]}
                    />
                    <div className="search-bar">
                        <div className="categories">
                            <div className="label">Categories:</div>
                            <Menu attribute="category"/>
                        </div>
                        <div className="right-side">
                            <div className="dropdown hidden-mobile">
                                <button className={tagsClass} onClick={() => tagsDropDownToggle()}>
                                    Tags
                                    <Icon className="chevron" name="chevron"/>
                                </button>
                            </div>
                            {/* TODO: Enable Archives */}
                            {/* <div className="dropdown">*/}
                            {/*     <button>*/}
                            {/*         Archive*/}
                            {/*         <Icon className="chevron" name="chevron"/>*/}
                            {/*     </button>*/}
                            {/* </div>*/}
                            {/* TODO: Add clear search button */}
                            <DebouncedSearchBox delay={500} />
                            <button className={`filters hidden-desktop ${tagsClass}`} onClick={() => tagsDropDownToggle()}>
                                Filters { tagsActive ? <Icon name="close"/> : <Icon name="filter"/>}
                            </button>
                        </div>
                    </div>
                    <div className={`filter-bar ${tagsClass}`}>
                        <div className="filter-bar-options">
                            <strong className="hidden-desktop">Tags</strong>
                            <CustomClearRefinements attribute="tags" className="hidden-mobile"/>
                            <button onClick={() => tagsDropDownToggle()}>Close</button>
                        </div>
                        <div className="filter-bar-selections">
                            <RefinementList attribute="tags" operator="and"/>
                        </div>
                    </div>
                    {/* TODO: Enable Archives */}
                    {/* <div className="filter-bar is-active">*/}
                    {/*     <div className="filter-bar-options">*/}
                    {/*         <button>Clear archive selection</button>*/}
                    {/*         <button>Close</button>*/}
                    {/*     </div>*/}
                    {/*     <div className="filter-bar-selections">*/}
                    {/*         <div className="item form-field radio">*/}
                    {/*             <input type="radio" name="archive" id="2018" value="2018"/><label className="form-field-label" htmlFor="2018">2018</label>*/}
                    {/*         </div>*/}
                    {/*         <div className="item form-field radio">*/}
                    {/*             <input type="radio" name="archive" id="2017" value="2017"/><label className="form-field-label" htmlFor="2017">2017</label>*/}
                    {/*         </div>*/}
                    {/*         <div className="item form-field radio">*/}
                    {/*             <input type="radio" name="archive" id="2016" value="2016"/><label className="form-field-label" htmlFor="2016">2016</label>*/}
                    {/*         </div>*/}
                    {/*         <div className="item form-field radio">*/}
                    {/*             <input type="radio" name="archive" id="2015" value="2015"/><label className="form-field-label" htmlFor="2015">2015</label>*/}
                    {/*         </div>*/}
                    {/*         <div className="item form-field radio">*/}
                    {/*             <input type="radio" name="archive" id="2014" value="2014"/><label className="form-field-label" htmlFor="2014">2014</label>*/}
                    {/*         </div>*/}
                    {/*     </div>*/}
                    {/* </div>*/}
                    <div className="search-results content-set-block single-row">
                        <Configure hitsPerPage={16} />
                        <Hits hitComponent={Hit} />
                        <Pagination />
                    </div>
                    <PoweredBy/>
                </InstantSearch>
            </div>
        </Layout>
    )
}

// noinspection JSUnusedGlobalSymbols
export default News

ClearRefinements.propTypes = {
    className: PropTypes.string,
    attribute: PropTypes.string,
    refine: PropTypes.func,
    items: PropTypes.array,
}

News.propTypes = {
    location: PropTypes.object,
    path: PropTypes.string,
}

