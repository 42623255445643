import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
export const useLatestResource = () => {
    const { singleContentfulResource, allMetaData } = useStaticQuery(
        graphql`
            query latestResource {
                singleContentfulResource: allContentfulResource(sort: {order: DESC, fields: updatedAt}, limit: 1) {
                    latestResource: edges {
                        node {
                            title
                            category
                            updatedAt(formatString: "DD/MM/YYYY")
                            readingTime
                            slug
                            image {
                                image {
                                    fluid(maxWidth: 600, maxHeight: 400) {
                                        aspectRatio
                                        base64
                                        sizes
                                        src
                                        srcSet
                                    }
                                }
                            }
                            contentBlocks {
                                ...ContentfulRichTextContentBlockFragment
                            }
                        }
                    }
                }
            }
        `,
    )

    return {
        singleContentfulResource: singleContentfulResource,
        allMetaData: allMetaData,
    }
}

useLatestResource.proptypes = {
    edges: PropTypes.any,
}
